exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-imprint-js": () => import("./../../../src/pages/en/imprint.js" /* webpackChunkName: "component---src-pages-en-imprint-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-privacy-js": () => import("./../../../src/pages/en/privacy.js" /* webpackChunkName: "component---src-pages-en-privacy-js" */),
  "component---src-pages-en-success-js": () => import("./../../../src/pages/en/success.js" /* webpackChunkName: "component---src-pages-en-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ua-imprint-js": () => import("./../../../src/pages/ua/imprint.js" /* webpackChunkName: "component---src-pages-ua-imprint-js" */),
  "component---src-pages-ua-index-js": () => import("./../../../src/pages/ua/index.js" /* webpackChunkName: "component---src-pages-ua-index-js" */),
  "component---src-pages-ua-privacy-js": () => import("./../../../src/pages/ua/privacy.js" /* webpackChunkName: "component---src-pages-ua-privacy-js" */),
  "component---src-pages-ua-success-js": () => import("./../../../src/pages/ua/success.js" /* webpackChunkName: "component---src-pages-ua-success-js" */)
}

